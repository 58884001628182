<template>
  <div class="actions">
    <b-button
      class="m-1"
      type="button"
      variant="success"
      title="Edit Account"
      @click="itemAction('edit-item', rowData, rowIndex)">
      <b-icon icon="pencil" aria-hidden="true"></b-icon>
    </b-button>
    <b-button
      class="m-1"
      type="button"
      variant="danger"
      title="Delete Account"
      @click="itemAction('delete-item', rowData, rowIndex)">
      <b-icon icon="trash" aria-hidden="true"></b-icon>
    </b-button>
    <b-button
      class="m-1"
      type="button"
      variant="primary"
      title="Send Payment Link"
      @click="sendPaymentLink(rowData)"
      :disabled="rowData.status !== 'for payment'">
      <b-icon icon="envelope" aria-hidden="true"></b-icon>
    </b-button>
  </div>
</template>

<script>
  const EditAccount = () => import('./EditEnterprise.vue');

  export default {
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    methods : {
      
      /**
       * Data Action
       *
       * @param action
       * @param data
       * @param index
       */
      itemAction(action, data, index) {
        if (action === 'edit-item') {
          this.$modal.show(EditAccount, {
            data : data,
          }, {
            height   : 'auto',
            adaptive : true,
          })
        }

        // DELETE
        if (action === 'delete-item') {
          this.$modal.show('dialog', {
            title : 'Please confirm',
            text  : `Are you sure you want to delete 
              <b>${data.email}</b>?`,
            buttons : [ {
              title   : 'Yes',
              handler : () => {
                this.$events.fire('loading-state', true);
                this.$http.delete('api/enterprise/account', {
                  data : {
                    'userId' : data.user_id,
                  },
                }).then(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success',
                    text  : `Deleted enterprise account <b>${data.email}</b> 
                      successfully!`,
                  });

                  this.$events.fire('loading-state', false);
                  this.$events.fire('reload');
                  this.$parent.reload();
                  this.$modal.hide('dialog');
                });
              },
            }, {
              title : 'No',
            } ],
          });
        }
      },

      /**
       * Send Payment Link
       * @param data
       */
      sendPaymentLink(data) {
        this.$modal.show('dialog', {
          title   : 'Send Payment Link',
          text    : `Do you want to send Payment Link to <b>${data.email}</b>?`,
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$modal.hide('dialog');
              this.$events.fire('loading-state', true);
              this.$http.post('api/enterprise/payment', {
                email          : data.email,
                subscriptionId : data.subscription_id,
                price          : data.custom_price,
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success',
                  text  : `Payment link sent to <b>${data.email}</b>`,
                });
                this.$events.fire('loading-state', false);
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },
    },
  }
</script>